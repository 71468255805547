.scanner {
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    width: 100%;
    padding: 20px;
    font-size: 3vw;
}

.scanner:hover {
    color: #000000;
    background-color: #ffffff;
    border-color: #000000;
}
